@import './standard/index.less';

.primary-button {
    width: 112px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 0 4px 6px 0 rgba(50, 120, 255, 0.2);
    background-color: @brand-color-normal;

    font-size: 16px;
    font-weight: 350;
    color: #ffffff;

    &:hover {
        cursor: pointer;
        background-color: #4584fe;
    }

    &:active {
        background-color: #1060ff;
    }
}

.secondary-button {
    min-width: 88px;
    height: 32px;
    border-radius: 2px;
    box-shadow: 0 4px 6px 0 rgba(50, 120, 255, 0.12);
    border: solid 1px @brand-color-normal;
    background-color: rgba(255, 255, 255, 0.2);

    color: @brand-color-normal;
    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 6px 0 rgba(50, 120, 255, 0.12);
        border-color: #4584fe;
        background-color: rgba(50, 120, 255, 0.05);
    }

    &:active {
        border-color: #1060ff;
        background-color: rgba(50, 120, 255, 0.1);
    }
}

.small-button {
    min-width: 64px;
    height: 24px;
    padding: 2px 13px 2px 14px;
    border-radius: 2px;
    border: solid 1px @brand-color-normal;
    background-color: rgba(255, 255, 255, 0.2);

    line-height: 1; // 文字垂直方向居中
    .mobile-font-size-14px();
    font-weight: 500;
    color: @brand-color-normal;

    &:hover {
        cursor: pointer;
        color: #4584fe;
        border-color: #4584fe;
        background-color: rgba(50, 120, 255, 0.05);
    }
    &:active {
        color: #1060ff;
        border-color: #1060ff;
        background-color: rgba(50, 120, 255, 0.1);
    }
}

.small-logo {
    width: 64px;
    height: 64px;

    img {
        width: 64px;
        height: 64px;
    }

    @media screen and (max-width: @mobile-width) {
        @mobile-logo-size: 52px;
        width: @mobile-logo-size;
        height: @mobile-logo-size;

        img {
            width: @mobile-logo-size;
            height: @mobile-logo-size;
        }
    }
}

.middle-logo {
    width: 96px;
    height: 96px;

    img {
        width: 96px;
        height: 96px;
    }

    margin-right: 28px;

    @media screen and (max-width: @mobile-width) {
        width: 56px;
        height: 56px;

        img {
            width: 56px;
            height: 56px;
        }

        margin-right: 14px;
    }
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;
    flex: none;
    overflow: hidden;
}

.app-item {
    display: flex;
    // max-width: 458px;
    overflow: hidden;

    :hover {
        cursor: pointer;
    }

    .logo {
        margin-right: 16px;
        @media screen and (max-width: @mobile-width) {
            margin-top: 8px;
        }
    }

    .main {
        overflow: hidden;
        flex: 1 1 auto;
        .content {
            flex: 1 1 auto;
            overflow: hidden;
        }

        .detail {
            @media screen and (min-width: @mobile-width) {
                height: 64px;
            }
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;

            h3 {
                margin-bottom: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .description {
                max-width: 250px;
                height: 1.5em;
                .mobile-font-size-14px();
                line-height: 1.57;
                color: #788088;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; //溢出用省略号显示
            }
        }

        .divider {
            position: relative;
            top: 0.5rem;
            @media screen and (max-width: @mobile-width) {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }
    }
}

.left-btn {
    width: 42px;
    height: 42px;
    background-image: url('../../resource/image/left-btn.svg');

    &:hover {
        background-image: url('../../resource/image/left-btn-dark.svg');
    }
}

.right-btn {
    width: 42px;
    height: 42px;
    background-image: url('../../resource/image/right-btn.svg');

    &:hover {
        background-image: url('../../resource/image/right-btn-dark.svg');
    }
}

.ant-tabs-nav {
    margin: 0px;

    &::before {
        border: 1px solid #cdd1d9;
    }

    .ant-tabs-nav-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1px;
        margin-top: 0px;

        .ant-tabs-tab {
            padding-top: 0;
            padding-bottom: 6px;
            height: 40px;

            div {
                font-size: 16px;
                font-weight: 350;
            }
        }

        .ant-tabs-tab-active {
            background-color: transparent;

            div {
                font-size: 20px;
                font-weight: 600;

                &::after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    display: block;
                    border-bottom: #4e8af9 2px solid;
                    position: absolute;
                    bottom: 0px;
                }
            }
        }

        .ant-tabs-ink-bar {
            background: transparent;

            &::after {
                content: '';
                width: 0;
                height: 2px;
                border-radius: 1.5px;
                text-align: center;
                display: none;
                background: #4e8af4;
                margin: 0 auto;
            }
        }
    }
}

.center {
    display: flex;
    justify-content: center;
}

.one-line {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.breadcrumb {
    display: flex;
    align-items: center;

    .decoration {
        width: 3px;
        height: 14px;
        background-color: @brand-color-normal;
        margin-right: 12px;
    }
    .breadcrumb {
        svg {
            fill: #7a7f8b;
            opacity: 0.6;
        }
    }
}

.model-card {
    // border: 1px solid black;
    @media screen and (min-width: @mobile-width) {
        height: 95px;
    }
    // max-width: 458px;
    overflow: hidden;

    :hover {
        cursor: pointer;
    }

    .name {
        font-size: 16px;
        line-height: 1.5;
        color: #23252d;
        font-weight: 500;
        margin-bottom: 4px;
    }

    .description {
        .mobile-font-size-14px();
        line-height: 1.57;
        color: #818691;
        height: 20px;
        // width: 300px;
        max-width: 100%;
        flex: 1 1 auto;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; //溢出用省略号显示
        display: inline-block;
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 1; //显示的行
    }

    hr {
        width: 100%;
        border-color: #e0e4e9;
    }

    .content-row {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        .content {
            align-items: center;
        }
    }

    .decoration {
        margin-right: 12px;

        .blue {
            width: 2px;
            height: 24px;
            background-color: @brand-color-normal;
        }

        .gray {
            width: 2px;
            height: 24px;
            background-color: #d8dde3;
        }
    }
}

.display-pad {
    height: 100%;
    overflow: hidden;
    padding: 0 24px;
    border-radius: 8px;
    box-shadow: 3px 4px 10px 0 rgba(120, 125, 135, 0.08);
    // border-style: solid;
    // border-width: 1px;
    // border-image-source: linear-gradient(118deg, #ffffff 24%, rgba(255, 255, 255, 0.85) 72%);
    // border-image-slice: 1;
    border: 1px solid white;
    background-image: linear-gradient(to bottom, #f7fbfe, #f7fbfe),
        linear-gradient(118deg, #ffffff 24%, rgba(255, 255, 255, 0.85) 72%);
    background-clip: border-box;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .ant-pagination-item .ant-pagination-item-active {
        background-color: @brand-color-normal;

        a {
            color: white;
        }
    }

    @media screen and (max-width: @mobile-width) {
        // padding: 0 12px;
    }
}

.select-group-button {
    padding: 2px;
    background-color: #cdd1d9;
    height: 40px;
    display: inline-flex;
    border-radius: 4px;

    span {
        cursor: pointer;
        padding: 10px 16px;
        line-height: 100%;
    }

    .selected {
        border-radius: 2px;
        box-shadow: 3px 4px 10px 0 rgba(120, 125, 135, 0.08);
        // border-style: solid;
        // border-width: 1px;
        // border-image-source: linear-gradient(115deg, #ffffff 24%, rgba(255, 255, 255, 0.85) 72%);
        // border-image-slice: 1;
        background-image: linear-gradient(to bottom, #f7fbfe, #f7fbfe),
            linear-gradient(115deg, #ffffff 24%, rgba(255, 255, 255, 0.85) 72%);
        background-origin: border-box;
        background-clip: content-box, border-box;
    }
}

.page-x-padding {
    padding: 0 32px;
    @media screen and (max-width: @mobile-width) {
        padding-left: @global-pad-x;
        padding-right: @global-pad-x;
    }
    // max-width: 1790px;
    max-width: 1568px;
    // min-width: 896px;
    width: 100%;
    margin: auto;
}

.page-padding {
    .page-x-padding();
    padding-bottom: 5vh;
}

.span-text-center {
    text-align: center;
}

.fallback {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
        width: 104px;
        height: 85px;
    }
    .hint {
        margin-top: 17px;
        .mobile-font-size-14px();
        text-align: center;
        color: #7a7f8b;
    }
}
