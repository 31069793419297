@import './standard/index.less';

.app-detail {
    margin-top: 5px;
    padding-bottom: 5vh;

    .display-pad {
        @media screen and (max-width: @mobile-width) {
            padding-top: 16px !important;
            padding-bottom: 16px !important;
        }
    }

    .long-introduction {
        .head-long-intro {
            font-size: 16px;
            line-height: 1.75;
            color: #8a8a8a;
            margin-bottom: 48px;
        }

        .detail-long-intro {
            border-radius: 8px;
            background-color: #f7f8fa;
            padding: 36px 24px 47px 24px;
            margin-bottom: 24px;

            h1 {
                font-size: 24px;
                font-weight: 600;
                color: #333333;
                margin-bottom: 8px;
            }
        }
    }

    .meta {
        line-height: normal;
        display: flex;
        flex-direction: column;
        .mobile-font-size-14px();
        color: #23242d;

        h2 {
            margin-bottom: 6px;
            font-size: 20px;
            font-weight: 500;
        }

        .field-name {
            width: 200px;
            height: 56px;
            .mobile-font-size-14px();
            font-weight: 500;
            line-height: 1.75;
        }

        .filed-value {
            font-weight: 200;
        }

        .secondary-btn button {
            width: 112px;
            height: 40px;
            border-radius: 2px;
            border: solid 1px #d5d9dd;
            background-color: #ffffff;
        }

        a .primary-btn .ant-btn button {
            width: 112px;
            height: 40px;
            border-radius: 2px;
            background-color: @brand-color-normal;
        }
    }

    div[role='separator'] {
        margin: 30px 0;
    }

    .app-store-swiper-container {
        .ant-image {
            height: 100%;
        }
        .cover {
            height: 100%;
            border-radius: 8px;
            box-shadow: 3px 4px 10px 0 rgba(120, 125, 135, 0.08);
            overflow: hidden;

            & + .ant-image-mask {
                border-radius: 8px;
            }
        }
    }

    .introduction {
        margin: 32px 0;
        .mobile-font-size-14px();
        line-height: 1.57;
        color: #23252d;
    }

    .title {
        margin: 48px 0 16px 0;
        @media screen and (max-width: @mobile-width) {
            margin: 28px 0 12px 0;
        }
    }

    .sub-title {
        font-size: 16px;
        font-weight: 500;
        color: #23252d;
    }

    .content {
        .mobile-font-size-14px();
        line-height: 1.57;
        color: #7a7f8b;
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .scene {
        // padding: 40px 16px;
        background: no-repeat bottom right url('../../resource//image/scene.svg'),
            linear-gradient(to bottom, #f7fbfe, #f7fbfe),
            linear-gradient(118deg, #ffffff 24%, rgba(255, 255, 255, 0.85) 72%), no-repeat bottom right;
    }

    .symbol {
        margin-bottom: 22px;
        margin-top: -10px;
    }

    .with-border {
        border-bottom: 1px solid #e0e4e9;
        padding-bottom: 40px;
        margin-bottom: 40px;
        @media screen and (max-width: @mobile-width) {
            padding-bottom: 16px;
            margin-bottom: 16px;
        }
    }

    .item {
        display: flex;
        align-items: flex-start;

        .item-content-wrapper {
            border-left: 1px solid #e0e4e9;
            width: 100%;
            height: 100%;
            padding-left: 20px;
            margin-left: 20px;
        }
    }

    .single-cover {
        max-height: 276px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
