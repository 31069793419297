@import './standard/index.less';

.home {
    .ant-carousel {
        @media screen and (max-width: @mobile-width) {
            margin-left: -@global-pad-x;
            margin-right: -@global-pad-x;
        }
    }

    @media screen and (min-width: @mobile-width) {
        padding-top: 20px;
    }

    .carousel {
        -webkit-transform: translate3d(0, 0, 0);
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 27px 10px -20px #999999;

        .slider {
            position: relative;

            .text {
                position: absolute;
                left: 56px;
                top: 56px;
                @media screen and (max-width: @mobile-width) {
                    left: 32px;
                    top: 32px;
                }

                color: white;

                h2 {
                    color: white;
                    font-size: 32px;
                    font-weight: 500;
                    margin-bottom: 8px;
                    @media screen and (max-width: @mobile-width) {
                        font-size: 28px;
                        width: 270px;
                    }
                }

                p {
                    .mobile-font-size-14px();
                    font-weight: 300;
                    max-width: 507px;
                    @media screen and (max-width: @mobile-width) {
                        width: 270px;
                    }
                }
            }

            img {
                max-height: 382px;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        @media screen and (max-width: @mobile-width) {
            border-radius: 0;
            box-shadow: none;

            img {
                height: 280px !important;
                object-fit: fill;
            }
        }
    }

    ul.slick-dots.slick-dots-bottom {
        justify-content: flex-start;
        margin-left: 56px;
        margin-bottom: 28px;

        @media screen and (max-width: @mobile-width) {
            left: -28px;
        }

        & li button {
            border-radius: 3px;
            height: 6px;
            width: 6px;

            &::before {
                content: '';
                position: absolute;
                top: -10px;
                right: -10px;
                bottom: -10px;
                left: -10px;
            }
        }

        & li.slick-active button {
            width: 16px;
            height: 6px;
        }
    }

    .tab-link {
        & span a {
            width: 64px;
            height: 22px;
            font-weight: 350;
            font-size: 16px;
            color: #7d7d7d;
        }

        & span a:hover {
            color: @brand-color-normal;
        }
    }

    .highlight {
        padding-top: 48px;
        @media screen and (max-width: @mobile-width) {
            padding-top: 16px;
        }
        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            span {
                font-size: 20px;
                font-weight: 500;
                color: #333333;
                margin-bottom: 4px;
            }

            .tab-link();
        }

        .highlight-card {
            display: flex;
            flex-direction: row;
            align-items: center;

            @media screen and (max-width: @mobile-width) {
                padding: 14px;
            }

            &.app {
                // border: 1px solid;
                // border-image-source: linear-gradient(124deg, #ffffff 23%, rgba(255, 255, 255, 0.85) 74%);
                // border-image-slice: 1;
                border: 1px solid white;

                .image {
                    img {
                        display: block;
                        width: 178px;
                        height: 138px;
                        border-radius: 15px;
                        box-shadow: 0 13px 20px -10px #999999;

                        @media screen and (max-width: @mobile-width) {
                            width: 150px;
                            height: auto;
                        }
                    }
                }

                .content {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-left: 17px;

                    .title {
                        margin-top: 18px;
                        width: 100%;
                        height: 25px;
                        font-size: 18px;
                        font-weight: 500;
                        color: #333333;

                        overflow: hidden;
                        text-overflow: ellipsis; //溢出用省略号显示
                        display: -webkit-box; //作为弹性伸缩盒子模型显示。
                        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
                        -webkit-line-clamp: 2; //显示的行

                        @media screen and (max-width: @mobile-width) {
                            margin-top: 0;
                        }
                    }

                    .description {
                        height: 44px;
                        margin-bottom: 25px;
                        .mobile-font-size-14px();
                        line-height: 1.57;
                        color: #7a7f8b;
                        margin-top: 5px;

                        overflow: hidden;
                        font-weight: normal;
                        text-overflow: ellipsis; //溢出用省略号显示
                        display: -webkit-box; //作为弹性伸缩盒子模型显示。
                        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
                        -webkit-line-clamp: 2; //显示的行
                    }

                    .arrow-icon {
                        display: flex;
                        color: @brand-color-normal;
                        display: flex;
                        align-items: center;

                        .anticon {
                            font-size: 12px;
                            margin-left: 5px;
                        }
                    }
                }
            }

            &.model {
                padding: 0 4px;
                border-radius: 8px;
                box-shadow: 3px 4px 10px 0 rgba(120, 125, 135, 0.08);
                // border-style: solid;
                // border-width: 1px;
                // border-image-source: linear-gradient(128deg, #ffffff 23%, rgba(255, 255, 255, 0.85) 75%);
                // border-image-slice: 1;
                border: 1px solid white;
                background-image: linear-gradient(to bottom, #f7fbfe, #f7fbfe),
                    linear-gradient(128deg, #ffffff 23%, rgba(255, 255, 255, 0.85) 75%);
                background-origin: border-box;
                background-clip: border-box;

                display: flex;
                flex-direction: column;

                div img {
                    height: 64px;
                    width: 64px;
                    border-radius: 50%;
                    margin-right: 16px;
                }

                .upper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    padding: 24px 20px;

                    @media screen and (max-width: @mobile-width) {
                        padding: 12px;
                    }

                    .model-info {
                        flex-grow: 1;

                        h3 {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 1.5;
                            color: #23252d;
                            margin-bottom: 8px;
                        }

                        .version {
                            .mobile-font-size-14px();
                            line-height: 1.57;
                            color: #7a7f8b;
                        }
                    }

                    .button {
                        width: 88px;
                        height: 32px;
                        border-radius: 2px;
                        box-shadow: 0 4px 6px 0 rgba(50, 120, 255, 0.12);
                        border: solid 1px @brand-color-normal;
                        background-color: rgba(255, 255, 255, 0.2);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .mobile-font-size-14px();
                        font-weight: 500;
                        color: @brand-color-normal;
                    }
                }

                .lower {
                    width: 100%;
                    height: 100%;
                    min-height: 110px;
                    padding: 16px 20px;
                    border-radius: 1px;
                    background-image: linear-gradient(to bottom, #f5f7f9, #edf2f5 0%, #f7fbfe);

                    @media screen and (max-width: @mobile-width) {
                        padding: 12px;
                        min-height: 0;
                    }

                    .description {
                        .mobile-font-size-14px();
                        line-height: 1.57;
                        color: #7a7f8b;
                        margin-top: 5px;

                        font-weight: normal;
                        overflow: hidden;
                        text-overflow: ellipsis; //溢出用省略号显示
                        display: -webkit-box; //作为弹性伸缩盒子模型显示。
                        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
                        -webkit-line-clamp: 3; //显示的行
                    }
                }
            }
        }

        & .highlight-card:hover {
            // width: 100%;
            // border-radius: 8px;
            // box-shadow: 0 2px 4px 0 rgba(176, 184, 195, 0.55);

            transform: translateY(-5px);
            transition: all 0.25s linear;
        }

        & .highlight-card:not(:hover) {
            transition: all 0.25s linear;
        }
    }

    .solution-summary {
        .tab-link();
        margin-top: 32px;
        @media screen and (max-width: @mobile-width) {
            margin-top: 16px;
        }

        .divider {
            margin: 16px 0;
            @media screen and (max-width: @mobile-width) {
                margin: 16px 0;
            }
        }

        .big-divider {
            margin-top: 44px;
            margin-bottom: 8px;
        }
    }

    .ant-tabs-left {
        > .ant-tabs-content-holder {
            border-left: none;
        }

        > .ant-tabs-nav {
            min-width: 30px !important;
            background-image: linear-gradient(179deg, #21262b 0%, #26272d 100%);

            > .ant-tabs-nav-wrap {
                width: 40px;

                .ant-tabs-tab {
                    width: 40px;
                    height: 112px;
                    padding: 8px;
                    white-space: normal;
                    word-break: break-all;
                    overflow: hidden;
                    margin-bottom: 0px;
                    border: none;
                }

                .ant-tabs-tab-active {
                    background: #0c0d0e;
                    color: rgba(255, 255, 255, 1);
                }

                .ant-tabs-ink-bar {
                    width: 0px !important;
                    height: 0px !important;
                    border: none;
                }
            }
        }
    }

    .description {
        background-repeat: no-repeat;
        border-radius: 2px;

        div {
            padding-left: 48px;
            padding-top: 74px;
            width: 515px;
            height: 48px;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #ffffff;

            h2 {
                width: 360px;
                height: 56px;
                font-size: 40px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #ffffff;
            }
        }
    }
}

.category-choose {
    span {
        width: 56px;
        height: 16px;
        margin: 0 24px;
        .mobile-font-size-14px();
        line-height: 1.14;
        color: #23252d;
        padding: 4px 8px;
        cursor: pointer;
    }

    .selected {
        border-radius: 2px;
        background-color: rgba(50, 120, 255, 0.1);
        .mobile-font-size-14px();
        color: @brand-color-normal;
    }
}
