@import '../standard/index.less';

.search-mobile-layout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;

    display: flex;
    align-items: center;
}

.search {
    box-shadow: 3px 2px 5px 0 rgba(120, 125, 135, 0.08);
    width: 240;
    height: 32;
    border-radius: 2px;

    .ant-input-affix-wrapper.ant-input-affix-wrapper-lg.ant-input-search.ant-select-selection-search-input {
        border-radius: 2px;
        border: solid 1px #cdd1d9;
        background-color: #ffffff;
        border-right: none;
    }

    .ant-input-affix-wrapper {
        line-height: 1.14;

        .ant-input-suffix {
            height: 16px;

            img {
                vertical-align: middle;
                border-style: none;
                background: #e8ecf0;
                height: 28px;
                opacity: 0.8;
                padding: 2px;
                font-size: 16px;
                border-radius: 1px;
            }
        }
    }

    .ant-input-affix-wrapper-lg {
        align-items: center;
        padding: 4px 1px 4px 8px;
        @media screen and (max-width: @mobile-width) {
            padding: 4px 8px;
        }

        .ant-input-lg {
            font-size: 14px;
            @media screen and (max-width: @mobile-width) {
                font-size: 16px;
                height: 18px;
            }
        }
    }

    @media screen and (max-width: @mobile-width) {
        width: 100%;
        height: 100%;
        .ant-select-selector {
            height: 100%;
            .ant-select-selection-search {
                height: 100%;
                .ant-select-selection-search-input {
                    // background-color: #e1e1e1;
                    height: 100%;
                    input {
                        height: 100%;
                        background-color: transparent;
                    }
                    span {
                        height: 100%;
                    }
                    img {
                        height: 42px;
                    }
                    .close-btn {
                        height: 24px;
                        margin-right: 8px;
                        background-color: transparent;
                    }
                    svg {
                        height: 100%;
                    }
                }
            }
        }
    }
}

.search-select {
    color: #333333;

    .ant-select-item-option-content span {
        color: #4e8af5;
    }
}
