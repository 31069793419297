@import './standard/index.less';

.search-result-page {
    .big-divider {
        margin: 0;
    }

    .search-result {
        .conclusion {
            margin-top: 8px;
            height: 22px;
            .mobile-font-size-14px();
            color: #7a7f8b;
        }
        h2 {
            margin: 24px 0;
            font-size: 20px;
            font-weight: 500;
            color: #333333;
        }
        .divider {
            margin: 12px 0;
        }
    }
}
