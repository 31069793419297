@import './standard/index.less';

.app-store-swiper {
    margin: 0 auto;
    width: 100%;
    border-radius: 3px;
    display: flex;
    position: relative;

    & > span {
        // x 轴方向
        z-index: 1000;
        flex: none;
        position: absolute;
        top: 50%;
    }

    .position-left {
        left: 0px;
        transform: translate(-50%, -50%);
        @media screen and (max-width: @mobile-width) {
            transform: translate(-40%, -50%);
        }
    }

    .position-right {
        right: 0px;
        transform: translate(50%, -50%);
        @media screen and (max-width: @mobile-width) {
            transform: translate(40%, -50%);
        }
    }

    .app-store-swiper-content {
        padding: 4px 0 4px;
        width: 100%;
        height: 100%;
        position: relative;
        overflow-x: hidden;

        .app-store-swiper-container {
            transition: transform 0.35s;
            display: flex;
            align-items: stretch;
        }
    }
}
