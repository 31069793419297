@import './color.less';

@mobile-width: 820px;
@global-pad-x: 18px;
@mobile-min-font-size: 16px;

.mobile-font-size-14px {
    font-size: 14px;
    @media screen and (max-width: @mobile-width) {
        font-size: 14px;
    }
}
