@import './components.less';

.aios-header {
    width: 100%;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: flex;
    align-items: flex-end;

    .home-page-component {
        .page-x-padding();
        opacity: 0.8;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
    }
}

header {
    .web-header {
        display: flex;
        flex-direction: column;
        // min-width: 896px;
        height: 56px;
        box-shadow: 3px 4px 10px 0 rgba(120, 125, 135, 0.08);
        // border-style: solid;
        // border-width: 1px;
        // border-image-source: linear-gradient(124deg, #ffffff 23%, rgba(255, 255, 255, 0.85) 74%);
        // border-image-slice: 1;
        border: 1px solid white;
        background-image: linear-gradient(to bottom, #f7fbfe, #f7fbfe),
            linear-gradient(124deg, #ffffff 23%, rgba(255, 255, 255, 0.85) 74%);
        background-origin: border-box;
        background-clip: border-box;

        .banner {
            .page-x-padding();

            display: flex;
            align-items: center;
            justify-content: space-between;

            font-size: 16px;
            font-weight: 600;
            color: #081018;
        }

        .logo {
            height: 34px;
            margin-right: 11px;
            display: inline;
        }

        a {
            display: flex;
            align-items: center;
        }
    }

    & > .breadcrumb {
        .page-x-padding();
        margin-top: 24px;
        margin-bottom: 24px;
    }
}
