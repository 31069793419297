body {
    h1,
    h2,
    h3,
    p,
    div {
        margin: 0;
        padding: 0;
        font-family: PingFang SC, Helvetica Neu, arial, hiragino sans gb, mocrosoft yahei ui, microsoft yahei, simsun,
            sans-serif;
    }
    background-color: #e8ecf0;
    background-clip: border-box;

    a {
        color: inherit;
        text-decoration: inherit;
    }

    button,
    input[type='submit'],
    input[type='reset'] {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
}
